import { defineStore } from 'pinia'
import type { State } from './types'
import { OpenAPI } from '@/api/core/OpenAPI'
import { configRetrieve, userManagementOauthTokenCreate, userManagementPasswordResetConfirmCreate, userManagementPasswordResetRequestCreate, userManagementPasswordRulesRetrieve, userManagementProfileChangePasswordUpdate, userManagementProfilePartialUpdate, userManagementProfileRetrieve, userManagementTokenCreate, userManagementTokenRefreshCreate, userManagementTokenVerifyCreate } from '@/api'
import { useBroadcastMessageStore } from '@/store/broadcast-message-store/useBroadcastMessageStore'
import type { CreateJwtTokenViaOauthRequest, CustomizedTokenObtainPairRequest, DashboardUser, PasswordChangeRequest, PasswordResetConfirmRequest, PasswordResetRequestRequest, PatchedProfileRequest, Profile, UserManagementTokenVerifyCreateData } from '@/api'

export const useAuthStore = defineStore('AuthStore', {
  state: (): State => ({
    accessToken: '',
    refreshToken: '',
    authInterval: '',
    broadCastInterval: '',
    appVersionInterval: '',
    newerFrontendVersionAvailable: false,
    allowUsernamePasswordLogin: false,
    oAuthInstances: [],
    features: [],
    user: undefined,
    userProfileData: undefined,
    router: [],
  }),
  getters: {
    isSameUser: state => {
      return (userData: DashboardUser) => state.user && (state.user.email === userData?.email)
    },
    OAuthProviders: state => {
      return state.oAuthInstances
    },
    getUser: state => {
      return state.user
    },
    getUserPermissionSet: state => {
      return new Set(state.user ? state.user.permissions : [])
    },
    getUserProfile: state => {
      return state.userProfileData as Profile
    },
    isAdmin: state => {
      return state.user?.is_superuser
    },
    isStaff: state => {
      return state.user?.is_staff
    },
  },
  actions: {
    hasPermission(permission: string) {
      return this.hasAllPermissions([permission])
    },
    featuresPermitted(permission: string) {
      return this.features?.includes(permission)
    },
    hasAnyPermission(permissions: string[]) {
      return this.user?.is_superuser || permissions.some(permission => this.getUserPermissionSet.has(permission))
    },
    hasAllPermissions(permissions: string[]) {
      return this.user?.is_superuser || permissions.every(permission => this.getUserPermissionSet.has(permission))
    },
    async isUserLoggedIn() {
      if (this.accessToken) {
        return true
      }
      else if (localStorage.getItem('accessToken') && localStorage.getItem('userPermissions')) {
        this.accessToken = JSON.parse(localStorage.getItem('accessToken') || '{}')
        this.user = JSON.parse(localStorage.getItem('userPermissions') || '{}')
        this.userProfileData = JSON.parse(localStorage.getItem('userProfile') || '{}')
        this.features = JSON.parse(localStorage.getItem('userFeatures') || '{}')

        return await this.isTokenValid(this.accessToken)
      }
      else {
        const broadcastMessageStore = useBroadcastMessageStore()

        await broadcastMessageStore.fetchBroadcastMessages()
        this.setDefaultIntervals()

        return false
      }
    },
    async isTokenValid(token: string | null | undefined) {
      try {
        return userManagementTokenVerifyCreate(<UserManagementTokenVerifyCreateData>{ requestBody: { token } }).then(_response => {
          this.refreshToken = JSON.parse(localStorage.getItem('refreshToken') || '{}')
          this.getPersistentToken(<string> this.refreshToken).then(async _res => {
            await this.getUserPermissions()

            const broadcastMessageStore = useBroadcastMessageStore()

            await broadcastMessageStore.fetchBroadcastMessages()
            this.setDefaultIntervals()
            this.authIntervalSet(<string> this.refreshToken)

            return true
          }).catch(async err => {
            console.log('refresh token error: ', err)
            await this.logout()

            const broadcastMessageStore = useBroadcastMessageStore()

            await broadcastMessageStore.fetchBroadcastMessages()
            this.setDefaultIntervals()
            await this.router.push({ name: 'login' })
          })

          return true
        }).catch(async () => {
          await this.logout()

          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          this.setDefaultIntervals()

          return false
        })
      }
      catch (err) {
        return false
      }
    },
    async logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userPermissions')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('userFeatures')
      this.authIntervalClear()
      this.accessToken = ''
      this.user = undefined
      this.features = []
      this.userProfileData = undefined
      OpenAPI.TOKEN = ''
    },
    authIntervalClear() {
      clearInterval(this.authInterval)
    },
    async authIntervalSet(refresh: string) {
      this.authInterval = setInterval(() => {
        this.getPersistentToken(refresh).then(async _res => {
          await this.getUserPermissions()

          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.authIntervalClear()

          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          await this.router.push({ name: 'login' })
        })
      }, 240000)
    },
    setDefaultIntervals() {
      this.broadcastMessageIntervalSet()
      this.appVersionIntervalSet()
    },
    broadcastMessageIntervalSet() {
      this.broadCastInterval = setInterval(async () => {
        const broadcastMessageStore = useBroadcastMessageStore()

        await broadcastMessageStore.fetchBroadcastMessages()
      }, 240000)
    },
    appVersionIntervalSet() {
      this.appVersionInterval = setInterval(async () => {
        const versionRes = await fetch(`${window.location.origin}/version.json`)
        const { build_number } = await versionRes.json()
        if (import.meta.env.VITE_BUILD_NUMBER && build_number && build_number !== import.meta.env.VITE_BUILD_NUMBER)
          this.newerFrontendVersionAvailable = true
      }, 600000)
    },
    async getOAuthToken(params: CreateJwtTokenViaOauthRequest) {
      return userManagementOauthTokenCreate({ requestBody: params }).then(async response => {
        const { refresh } = response

        localStorage.setItem('refreshToken', JSON.stringify(refresh))
        this.refreshToken = refresh
        this.authIntervalSet(refresh)

        return this.getPersistentToken(refresh).then(async _res => {
          await this.getUserPermissions()

          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.authIntervalClear()

          await this.router.push({ name: 'login' })
        })
      }).catch(err => {
        throw err
      })
    },
    async getPersistentToken(refresh: string) {
      return userManagementTokenRefreshCreate({ requestBody: { refresh } }).then(response => {
        const { access } = response

        localStorage.setItem('accessToken', JSON.stringify(access))
        this.accessToken = access
        OpenAPI.TOKEN = access
      }).catch(err => {
        throw err
      })
    },
    async getAccessToken(params: CustomizedTokenObtainPairRequest) {
      return userManagementTokenCreate({ requestBody: params }).then(async response => {
        const { refresh } = response

        localStorage.setItem('refreshToken', JSON.stringify(refresh))
        this.refreshToken = refresh
        this.authIntervalSet(refresh)

        return this.getPersistentToken(refresh).then(async () => {
          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          await this.getUserPermissions()

          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.authIntervalClear()

          await this.router.push({ name: 'login' })
        })
      }).catch(err => {
        throw err
      })
    },
    async resetForgotPassword(data: PasswordResetRequestRequest) {
      return userManagementPasswordResetRequestCreate({ requestBody: data }).then(async () => {
        return true
      }).catch(err => {
        throw err
      })
    },
    async confirmResetForgotPassword(token: string, uidb64: string, new_password: PasswordResetConfirmRequest) {
      return userManagementPasswordResetConfirmCreate({ requestBody: new_password, token, uidb64 }).then(async () => {
        return true
      }).catch(err => {
        throw err
      })
    },
    async getUserPermissions() {
      return configRetrieve().then(async response => {
        const { user, features, allow_password_login, oauth_configurations } = response

        this.user = user
        this.features = features
        this.allowUsernamePasswordLogin = allow_password_login
        this.oAuthInstances = oauth_configurations
        localStorage.setItem('userPermissions', JSON.stringify(user))
        localStorage.setItem('userFeatures', JSON.stringify(features))

        return true
      }).catch(err => {
        console.log('could not get user permissions', err.body)
      })
    },
    async getUserProfileData() {
      return userManagementProfileRetrieve().then(async response => {
        this.userProfileData = response
        localStorage.setItem('userProfile', JSON.stringify(response))

        return true
      }).catch(err => {
        throw err
      })
    },
    async profileUpdate(data: PatchedProfileRequest) {
      return userManagementProfilePartialUpdate({ requestBody: data }).then(async response => {
        this.userProfileData = response

        if (this.user) {
          this.user.first_name = response.first_name
          this.user.last_name = response.last_name
        }

        return true
      }).catch(err => {
        throw err
      })
    },
    async changePassword(data: PasswordChangeRequest) {
      return userManagementProfileChangePasswordUpdate({ requestBody: data }).then(async () => {
        return true
      }).catch(err => {
        throw err
      })
    },
    async getPasswordRules() {
      return userManagementPasswordRulesRetrieve().then(async response => {
        return response
      }).catch(err => {
        throw err
      })
    },
  },
})

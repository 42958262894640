import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { OpenAPI } from '@/api/core/OpenAPI'
import { useAuthStore } from '@/store/auth-store/useAuthStore'
import routes from '~pages'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/pages/user-profile',
      redirect: () => ({ name: 'index' }),
    },
    {
      path: '/',
      redirect: () => ({ name: 'sessions' }),
    },
    ...setupLayouts(routes),
  ],
})

router.beforeEach(async to => {
  const authStore = useAuthStore()
  const { hasPermission, featuresPermitted } = authStore
  const loggedInUser = await authStore.isUserLoggedIn()

  if (!loggedInUser && !to.meta.DontRedirectToLogin) {
    if (authStore.newerFrontendVersionAvailable)
      window.location.href = '/login'
    else
      return { name: 'login' }
  }
  else if (loggedInUser && to.meta.requiresPermission) {
    OpenAPI.TOKEN = JSON.parse(localStorage.getItem('accessToken') || '{}')

     
    // @ts-expect-error
    if (!(hasPermission(to.meta.requiresPermission) && featuresPermitted(to.meta.requiresFeature))) {
      if (authStore.newerFrontendVersionAvailable)
        window.location.href = '/'
      else
        return { name: 'index' }
    }
    else {
      if (authStore.newerFrontendVersionAvailable)
        window.location.href = to.fullPath
    }
  }
  else {
    OpenAPI.TOKEN = JSON.parse(localStorage.getItem('accessToken') || '{}')
    if (authStore.newerFrontendVersionAvailable)
      window.location.href = to.fullPath
  }
})

export default router
